import services from 'services';
import {describeThrow} from 'utils/errors';
import {pipe, prop, map} from 'ramda';
import * as normalize from 'utils/normalize';
import {getResponseData} from 'utils/app';
import msgs from 'dicts/messages';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getBuildings = query =>
	httpJson('get', `/buildings/projectSearch`, query)
		.catch(describeThrow(intl.formatMessage({id: 'Failed to search buildings'})))
		.then(prop('data'));

export const postCondo = condo =>
	httpJson('post', '/condominiums', {}, {body: condo})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to save'})))
		.then(getResponseData(normalize.condo));

export const getProjects = query =>
	httpJson('get', '/projects', {
		//groupBy: 'customerAcquisitionStates',
		checkUserRole: 'customerAcquisition',
		include: 'activities',
		...query,
	}).catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})));

export const getProject = (projectId, query) =>
	httpJson('get', `/projects/${projectId}`, {
		...query,
	}).catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})));

export const getProjectStates = (query = {}) =>
	httpJson('get', '/projectStates', {
		...query,
	}).catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})));

export const getProjectStateCategories = (query = {}) =>
	httpJson('get', '/projectStateCategories', {
		...query,
	}).catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})));

export const getProjectTypes = (query = {}) =>
	httpJson('get', '/projectTypes', {
		...query,
	}).catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})));

export const getProjectType = (id, query = {}) =>
	httpJson('get', `/projectTypes/${id}`, {
		...query,
	}).catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})));

export const saveProjectType = projectType =>
	httpJson('post', '/projectTypes', {}, {body: projectType}).catch(
		describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})),
	);

export const updateProjectType = (id, projectType) =>
	httpJson('put', `/projectTypes/${id}`, {}, {body: projectType}).catch(
		describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})),
	);

export const deleteProjectType = id =>
	httpJson('delete', `/projectTypes/${id}`).catch(
		describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})),
	);

export const saveProjectState = projectState =>
	httpJson('post', '/projectStates', {}, {body: projectState}).catch(
		describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})),
	);

export const updateProjectState = (id, projectState) =>
	httpJson('put', `/projectStates/${id}`, {}, {body: projectState}).catch(
		describeThrow(intl.formatMessage({id: msgs.saveFailed})),
	);

export const deleteProjectState = id =>
	httpJson('delete', `/projectStates/${id}`).catch(
		describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})),
	);

export const saveProject = project =>
	httpJson('post', '/projects', {}, {body: project}).catch(
		describeThrow(intl.formatMessage({id: msgs.saveFailed})),
	);

export const updateProject = (id, project) =>
	httpJson('put', `/projects/${id}`, {}, {body: project}).catch(
		describeThrow(intl.formatMessage({id: msgs.saveFailed})),
	);

export const deleteProject = id =>
	httpJson('delete', `/projects/${id}`).catch(
		describeThrow(intl.formatMessage({id: msgs.deleteFailed})),
	);

export const saveProjectStatesOrder = newOrder =>
	httpJson('post', '/projectStates/setNewOrder', {}, {body: {newOrder}}).catch(
		describeThrow(intl.formatMessage({id: msgs.saveFailed})),
	);

export const getEventTypes = () =>
	httpJson('get', '/eventTypes').catch(
		describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})),
	);

export const getEvents = query =>
	httpJson('get', '/events', {
		...query,
	}).catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})));

export const createEvent = event =>
	httpJson('post', '/events', {}, {body: event}).catch(
		describeThrow(intl.formatMessage({id: msgs.saveFailed})),
	);

export const updateEvent = (id, event) =>
	httpJson('put', `/events/${id}`, {}, {body: event}).catch(
		describeThrow(intl.formatMessage({id: msgs.saveFailed})),
	);

export const getEvent = (id, query) =>
	httpJson('get', `/events/${id}`, {
		...query,
	}).catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})));

export const attachBuildingToProject = (projectId, buildingId) =>
	httpJson(
		'post',
		`/projects/${projectId}/attach`,
		{},
		{
			body: {
				buildingId,
			},
		},
	).catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})));

export const detachBuildingFromProject = (projectId, buildingId) =>
	httpJson(
		'post',
		`/projects/${projectId}/detach`,
		{},
		{
			body: {
				buildingId,
			},
		},
	).catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})));

export const attachClientToProject = (projectId, clientId) =>
	httpJson('post', `/projects/${projectId}/attach`, {}, {body: {clientId}}).catch(
		describeThrow(intl.formatMessage({id: msgs.saveFailed})),
	);

export const detachClientFromProject = (projectId, clientId) =>
	httpJson('post', `/projects/${projectId}/detach`, {}, {body: {clientId}}).catch(
		describeThrow(intl.formatMessage({id: msgs.saveFailed})),
	);

export const createProjectComment = (projectId, comment) =>
	httpJson('post', `/projectComments`, {projectId}, {body: comment}).catch(
		describeThrow(intl.formatMessage({id: msgs.saveFailed})),
	);

export const updateProjectComment = (projectId, commentId, comment) =>
	httpJson('put', `/projectComments/${commentId}`, {projectId}, {body: comment}).catch(
		describeThrow(intl.formatMessage({id: msgs.saveFailed})),
	);

export const getProjectComments = (projectId, query) =>
	httpJson('get', `/projectComments`, {projectId, ...query}).catch(
		describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})),
	);

export const deleteProjectComment = (projectId, commentId) =>
	httpJson('delete', `/projectComments/${commentId}`, {projectId}).catch(
		describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})),
	);

export const getUsers = (query = {}) =>
	httpJson('get', '/users', {
		_limit: '999',
		_sort: 'lastName',
		...query,
	})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data'), map(normalize.user)));
